<template>
    <b-sidebar
        id="sidebar-right"
        title="Messages"
        right
        shadow
        :visible="visibility"
        backdrop
        :z-index="9999"
        :width="sidebarWidth"
        @shown="subscribeToMessages"
        @hidden="unsubscribeFromMessages"
        overflow-hidden
    >
        <b-card v-show="busy" class="m-2">
            <template #header>
                <message-header
                    @expand-sidebar="expandMessages"
                    :width-selector="widthSelector"
                ></message-header>
            </template>
            <b-card-text style="height: 100%">
                <message-spinner/>
            </b-card-text>
        </b-card>

        <b-card v-show="!busy" class="m-2" overflow-hidden style="height: 98%">
            <template #header>
                <message-header
                    @expand-sidebar="expandMessages"
                    :width-selector="widthSelector"
                ></message-header>
            </template>

            <b-card-text style="overflow-y: scroll; height: 100%" v-on:scroll="detectTopOfMessages" ref="cardParent">
                <div v-if="cardParentIsLoading">
                    <message-spinner/>
                </div>
                <div ref="messageList" style="overflow-y: scroll; scrollbar-width: none;">
                    <div v-for="(message, index) in messages" :key="index">
                        <message-component :message="message"></message-component>
                    </div>
                </div>
            </b-card-text>

            <template #footer>
                <message-alert-panel></message-alert-panel>
                <message-input @messageSent="scrollToBottom"></message-input>
            </template>
        </b-card>
    </b-sidebar>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import MessageAlertPanel from "./MessageAlertPanel.vue";
import MessageInput from "./MessageInput.vue";
import MessageComponent from "./MessageComponent.vue";
import MessageHeader from "./MessageHeader.vue";
import MessageSpinner from "./MessageSpinner.vue";

export default {
    name: "MessagingPanel",
    title: "Messaging Panel",
    components: {
        MessageSpinner,
        MessageHeader,
        MessageComponent,
        MessageInput,
        MessageAlertPanel,
    },
    data: function () {
        return {
            message: null,
            sidebarWidth: "33%",
            widthSelector: "<",
            busy: false,
            channel: null,
            readMessagesTimer: null,
            cardParentIsLoading: false,
            shouldScroll: true,
        };
    },

    mounted() {
        this.setUpChannels();
        //  this bug thankfully found by Rafael!
        if(this.visibility) {
            this.subscribeToMessages()
        }
    },
    methods: {
        ...mapActions({
            sendNewMessage: "messages/sendNewMessage",
            fetchEpisode: "episode/fetchEpisode",
            loadMessages: "messages/loadMessages",
            addMessageToMessages: "messages/addMessageToMessages",
            closeMessages: "messages/closeMessages",
            dispatchMarkConversationAsRead: "messages/dispatchMarkConversationAsRead",
            getNextPageOfMessages: "messages/getNextPageOfMessages",
        }),
        ...mapMutations({
            setEpisodeId: "episode/setEpisodeId",
            setSendAssessments: "messages/setSendAssessments",
            setSendVitalSigns: "messages/setSendVitalSigns",
            setSendInterventions: "messages/setSendInterventions",
            markEpisodeConversationAsRead: "episodes/markEpisodeConversationAsRead"
        }),

        setUpChannels() {
            const Pusher = require("pusher-js");

            Pusher.logToConsole = true;
            this.pusher = new Pusher(process.env.VUE_APP_WEB_SOCKET_KEY, {
                cluster: process.env.VUE_APP_WEB_SOCKET_CLUSTER,
            });
        },

        scrollToBottom() {

            const element = this.$refs.messageList;

            if (element) {
                element.scrollIntoView(false);
                this.prepareToMarkAsRead();
            }
        },

        async detectTopOfMessages() {

            if (this.$refs.cardParent.scrollTop === 0) {

                await this.fetchNextPageOfMessages()
            }
        },

        getOldestId() {

            return this.messages.reduce((previous, current) => previous.id < current.id ? previous : current)
        },

        subscribe() {
            this.channel = this.pusher.subscribe(
                `message.${this.getMessageEpisodeId}`
            );
            this.channel.bind("message.event", async (message) => {
                this.addMessageToMessages(message);
                this.clearSelections()
                await this.fetchEpisode();
                await this.loadMessages();
                this.scrollToBottom();
            });
        },

        async validateSubscription() {
            this.clearSelections();
            if (this.getEpisodeId !== this.getMessageEpisodeId) {
                this.busy = true;

                if (this.getEpisodeId !== null) {

                    this.setEpisodeId(this.getEpisodeId);
                }

                if (this.getEpisodeId === null && this.getMessageEpisodeId !== null) {
                    this.setEpisodeId(this.getMessageEpisodeId)

                }

                if (this.getEpisodeId === null && this.getMessageEpisodeId === null) {
                    console.error("Episode not loaded.")
                    return

                }

                await this.fetchEpisode();
                await this.loadMessages();
                this.scrollToBottom()
                this.busy = false;
            }
        },

        async subscribeToMessages() {
            await this.validateSubscription();
            this.scrollToBottom();
            this.subscribe();
        },
        unsubscribeFromMessages() {
            // this.pusher.leave(`message.${this.getMessageEpisodeId}`)
            if (this.channel) {
                this.channel.unbind("message.event");
            }
            this.closeMessages();
        },
        expandMessages() {
            if (this.widthSelector === "<") {
                this.sidebarWidth = "66%";
                this.widthSelector = ">";
            } else {
                this.sidebarWidth = "33%";
                this.widthSelector = "<";
            }
        },
        clearSelections() {
            this.setSendAssessments(false);
            this.setSendInterventions(false);
            this.setSendVitalSigns(false);
        },
        prepareToMarkAsRead() {
            setTimeout(() => {
                this.markConversationAsRead()
            }, 1000)
        },
        markConversationAsRead() {
            const episodeId = this.getEpisodeId
            this.markEpisodeConversationAsRead(episodeId)
            this.dispatchMarkConversationAsRead(episodeId)
        },
        async fetchNextPageOfMessages() {
            //  load next page
            this.cardParentIsLoading = true
            let currentScrollHeightValue = this.$refs.cardParent.scrollHeight
            //  fetch records
            await this.getNextPageOfMessages()
            let newScrollHeightValue = this.$refs.cardParent.scrollHeight

            if(newScrollHeightValue > currentScrollHeightValue) {

                this.$refs.cardParent.scrollTop = newScrollHeightValue - currentScrollHeightValue
            }
            this.cardParentIsLoading = false
        }
    },
    computed: {
        ...mapGetters({
            visibility: "messages/panelState",
            messages: "messages/messages",
            user: "authentication/user",
            getEpisodeId: "episode/getEpisodeId",
            episode: "episode/getEpisode",
            getMessageEpisodeId: "messages/getEpisodeId",
        }),
    },
};
</script>

<style>
.message {
    border-radius: 5px;
    border-color: #000000;
    border-width: 1px;
    margin-top: 1rem;
    padding: 1rem;
}

.myMessage {
    background-color: #407fff;
    color: white;
    margin-left: 25%;
}

.partnerMessage {
    background: grey;
    color: black;
    margin-right: 25%;
}

.small {
    font-size: 8pt;
}

.float-right {
    float: right;
}

::-webkit-scrollbar {
    width: 20px;
}

.header--template {
    display: flex;
    padding: 0;
    max-height: 70px;
}

.header--button {
    width: 40px;
    height: 40px;
}

.icon {
    position: relative;
    left: -12px;
    top: -10px;
}

.header--patient-info {
    display: flex;
    margin-left: 8px;
    padding-bottom: 8px;
    flex-direction: column;
    align-self: center;
}

.header--patient-name {
    font-size: 24px;
}

.header--patient-room {
    font-size: 18px;
}

@media screen and (max-width: 1024px) {
    .header--patient-name {
        font-size: 18px;
    }

    .header--patient-room {
        font-size: 14px;
    }
}
</style>
