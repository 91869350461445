import { render, staticRenderFns } from "./VitalSignAlert.vue?vue&type=template&id=5cc7aa2c&scoped=true&"
import script from "./VitalSignAlert.vue?vue&type=script&lang=js&"
export * from "./VitalSignAlert.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cc7aa2c",
  null
  
)

export default component.exports