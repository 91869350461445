<template>
    <div
        :class="{
                myMessage: isMyMessage,
                partnerMessage: !isMyMessage,
                message: true,
              }"
    >
        <div>
            <strong>{{ message.author }}</strong>
        </div>

        <intervention-alert
            v-for="intervention in message.escalations"
            :key="intervention.id"
            :intervention=intervention
            class="bg-white rounded medium"
        ></intervention-alert>

        <symptom-alert
            v-for="assessment in message.assessments"
            :key="assessment.id"
            :assessment=assessment
            class="bg-white rounded medium"
        ></symptom-alert>

        <vital-sign-alert
            v-for="vitalSign in message.vitalSigns"
            :key="vitalSign.id"
            :vital-sign=vitalSign
            class="bg-white rounded medium"
        ></vital-sign-alert>

        <div class="mt-2">{{ message.content }}</div>
        <div class="small float-right">
            {{ message.created_at | formatDate }}
        </div>
    </div>
</template>

<script>
import InterventionAlert from "./InterventionAlert.vue";
import SymptomAlert from "./SymptomAlert.vue";
import moment from "moment/moment";
import VitalSignAlert from "./VitalSignAlert.vue";

export default {
    name: "MessageComponent",
    components: {VitalSignAlert, SymptomAlert, InterventionAlert},
    props: {
        message: {
            type: Object,
            required: true,
            default: function () {
                return {
                    user_id: 0,
                    author: "unknown",
                    interventions: [],
                    assessments: [],
                    vitalSigns: [],
                    content: "",
                    created_at: null,
                }
            },
        },
    },
    computed: {
        isMyMessage() {

            return this.message.author_id === this.$store.state.user.id
        },
    },
    filters: {
        formatDate: function (value) {
            if (!value) return "";
            let momentObject = moment(value);

            return momentObject.format('MMMM Do YYYY, h:mm:ss a');
        },
    },
}
</script>

<style scoped>

</style>