<template>
    <b-container fluid="xl" class="resident-form-container">
        <div v-if="isSaving" class="text-center mt-10 mr-auto ml-auto mb-auto">
            <h3>Saving...</h3>
            <b-spinner variant="info"></b-spinner>
        </div>
        <div v-else>
            <!-- the header of the form -->
            <b-row>
                <b-col sm="12">
                    <!-- Close, Resident Name and Room, and Saved Buttons -->
                    <b-row class="resident-modal-header">
                        <b-col sm="2">
                            <b-button
                                variant="seconday"
                                text-variant="white"
                                class="float-left resident-modal-header-button"
                                @click="closeModal"
                            >CLOSE
                            </b-button
                            >
                        </b-col>
                        <b-col sm="8" class="resident-modal-header-info">
                            <span>{{ patientName }} - {{ roomNumber }}</span>
                        </b-col>
                        <b-col sm="2">
                            <b-button
                                v-if="showSaveButton"
                                variant="seconday"
                                text-variant="white"
                                class="float-right resident-modal-header-button"
                                @click="saveResidentData"
                            >{{ saveButtonText }}
                            </b-button
                            >
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <!-- close of the header form -->

            <!-- body of the form -->
            <b-row>
                <!-- menu sidebar -->
                <b-col class="mt-3 resident-data-menu-sidebar">
                    <b-container fluid style="padding: 0">
                        <b-row
                            :class="{
                'resident-form-menu-item':
                  !showBasicData &&
                  !showClinicalInfo &&
                  !showCareDirectives &&
                  !showMedicationRecon &&
                  !showImmunization,
                'resident-form-menu-item-selected':
                  showBasicData ||
                  showClinicalInfo ||
                  showCareDirectives ||
                  showMedicationRecon ||
                  showImmunization,
              }"
                            @click="doShowBasicInfo"
                            align-v="center"
                        >
                            <b-col cols="2 pr-0 text-center resident-menu--img-wrap">
                                <img
                                    src="@/assets/Icons/popup-screens/PNG Black icons 4 Popup screens/BasicResident ICONx64.png"
                                    height="32px"
                                    class="resident-menu--img"
                                />
                            </b-col>
                            <b-col cols="auto mr-auto">Basic Info</b-col>
                        </b-row>

                        <b-row
                            v-if="
                showBasicData ||
                showClinicalInfo ||
                showCareDirectives ||
                showMedicationRecon ||
                showImmunization
              "
                            :class="{
                'resident-form-menu-item':
                  !showBasicData &&
                  !showClinicalInfo &&
                  !showCareDirectives &&
                  !showMedicationRecon &&
                  !showImmunization,
                'resident-form-menu-item-selected':
                  showBasicData ||
                  showClinicalInfo ||
                  showCareDirectives ||
                  showMedicationRecon ||
                  showImmunization,
              }"
                            @click="doShowResidentReg"
                            align-v="center"
                        >
                            <b-col offset="1" cols="auto mr-auto"
                            >Resident Registration
                            </b-col
                            >
                            <b-col cols="auto pl-0 pr-0 text-right"
                            ><i class="fas fa-angle-right" v-if="showBasicData"
                            /></b-col>
                        </b-row>

                        <b-row
                            v-if="
                showBasicData ||
                showClinicalInfo ||
                showCareDirectives ||
                showMedicationRecon ||
                showImmunization
              "
                            :class="{
                'resident-form-menu-item':
                  !showBasicData &&
                  !showClinicalInfo &&
                  !showCareDirectives &&
                  !showMedicationRecon,
                'resident-form-menu-item-selected':
                  showBasicData ||
                  showClinicalInfo ||
                  showCareDirectives ||
                  showMedicationRecon ||
                  showImmunization,
              }"
                            @click="doShowMedicationRecon"
                            align-v="center"
                        >
                            <b-col offset="1" cols="auto mr-auto">Medication Recon</b-col>
                            <b-col cols="auto pl-0 pr-0 text-right"
                            ><i
                                class="fas fa-angle-right float-right"
                                v-if="showMedicationRecon"
                            /></b-col>
                        </b-row>

                        <!-- Immunization side nav entry -->
                        <b-row
                            v-if="
                showBasicData ||
                showClinicalInfo ||
                showCareDirectives ||
                showMedicationRecon ||
                showImmunization
              "
                            :class="{
                'resident-form-menu-item':
                  !showBasicData &&
                  !showClinicalInfo &&
                  !showCareDirectives &&
                  !showMedicationRecon &&
                  !showImmunization,
                'resident-form-menu-item-selected':
                  showBasicData ||
                  showClinicalInfo ||
                  showCareDirectives ||
                  showMedicationRecon ||
                  showImmunization,
              }"
                            @click="doShowImmunization"
                            align-v="center"
                        >
                            <b-col offset="1" cols="auto mr-auto">Immunization</b-col>
                            <b-col cols="auto pl-0 pr-0 text-right"
                            ><i
                                class="fas fa-angle-right float-right"
                                v-if="showImmunization"
                            /></b-col>
                        </b-row>

                        <b-row
                            :class="{
                'resident-form-menu-item': !showCareTeams,
                'resident-form-menu-item-selected': showCareTeams,
              }"
                            align-v="center"
                            @click="doShowCareTeams"
                        >
                            <b-col cols="2 pr-0 text-center resident-menu--img-wrap">
                                <img
                                    src="@/assets/Icons/popup-screens/PNG Black icons 4 Popup screens/CareTeam ICONx64.png"
                                    height="32px"
                                    class="resident-menu--img"
                                />
                            </b-col>
                            <b-col cols="auto mr-auto">Care Team</b-col>
                        </b-row>
                        <b-row
                            :class="{
                'resident-form-menu-item': !showSignsAndSymptoms,
                'resident-form-menu-item-selected': showSignsAndSymptoms,
              }"
                            @click="doShowSignsAndSymptoms"
                            align-v="center"
                        >
                            <b-col cols="2 pr-0 text-center resident-menu--img-wrap">
                                <img
                                    src="@/assets/Icons/popup-screens/PNG Black icons 4 Popup screens/SignsSymptoms ICONx64 BLACK.png"
                                    height="32px"
                                    class="resident-menu--img"
                                />
                            </b-col>
                            <b-col cols="auto mr-auto">Change in Condition</b-col>
                        </b-row>
                        <b-row
                            :class="{
                'resident-form-menu-item': !showVitalSigns,
                'resident-form-menu-item-selected': showVitalSigns,
              }"
                            @click="doShowVitalSigns"
                            align-v="center"
                        >
                            <b-col cols="2 pr-0 text-center resident-menu--img-wrap">
                                <img
                                    src="@/assets/Icons/popup-screens/PNG Black icons 4 Popup screens/VitalSigns ICONx64 BLACK.png"
                                    height="32px"
                                    class="resident-menu--img"
                                />
                            </b-col>
                            <b-col cols="auto mr-auto">Vital Signs</b-col>
                        </b-row>
                        <b-row
                            :class="{
                'resident-form-menu-item': !showInterventions,
                'resident-form-menu-item-selected': showInterventions,
              }"
                            @click="doShowInterventions"
                            align-v="center"
                        >
                            <b-col cols="2 pr-0 text-center resident-menu--img-wrap">
                                <img
                                    src="@/assets/Icons/popup-screens/PNG Black icons 4 Popup screens/Intervention ICONx64 BLACK.png"
                                    height="32px"
                                    class="resident-menu--img"
                                />
                            </b-col>
                            <b-col cols="auto mr-auto">Escalations</b-col>
                        </b-row>
                    </b-container>
                </b-col>
                <!-- menu sidebar -->

                <!-- main form -->
                <b-col sm="4" md="9" class="resident-editor--main">
                    <episode-resident-data v-if="showBasicData"></episode-resident-data>
                    <episode-clinical-information
                        v-if="showClinicalInfo"
                    ></episode-clinical-information>
                    <episode-medication-reconcilliation
                        v-if="showMedicationRecon"
                    ></episode-medication-reconcilliation>
                    <episode-care-team v-if="showCareTeams"></episode-care-team>
                    <episode-vital-signs v-if="showVitalSigns"></episode-vital-signs>
                    <episode-intervention v-if="showInterventions"></episode-intervention>
                    <episode-signs-and-symptoms
                        v-if="showSignsAndSymptoms"
                        v-on:did-sign-and-symptom-save="didSignAndSymptomSave"
                        ref="signsAndSymptomsComponent"
                    ></episode-signs-and-symptoms>

                    <resident-care-directives
                        v-if="showCareDirectives"
                    ></resident-care-directives>
                    <!-- Immunizatio main -->
                    <immunization-info v-if="showImmunization"></immunization-info>
                </b-col>

                <b-modal
                    ref="modal-for-basic-info-validation"
                    centered
                    title="Validation"
                    ok-only
                    header-text-variant="danger"
                >
                    <p class="my-4">
                        Please ensure that all resident information except room number is
                        filled in.
                    </p>
                    <p>{{ this.saved.messages }}</p>
                </b-modal>
                <!-- main form end -->
            </b-row>
        </div>

        <b-row>
            <div
                class="resident-messages"
                v-if="showMsgBtn"
                @click="openMsgWindow(episode)"
            >
                <div class="messages-blue-icon"></div>
            </div>
        </b-row>

        <b-modal
            ref="modal-unsaved-changes"
            title="Unsaved Changes"
            centered
            header-bg-variant="dark"
            header-text-variant="light"
            hide-footer
            hide-header-close
        >
            <p class="my-4">
                You have unsaved changes. Would you like to save your changes?
            </p>
            <div class="text-center">
                <app-button @click="saveResidentData" :disabled="isSaving">{{ saveButtonText }}</app-button>
                <app-button @click="exitEpisode()" isDanger :disabled="isSaving">CLOSE</app-button>
            </div>
        </b-modal>

        <b-modal
            ref="modal-error-saving"
            id="modal-error-saving"
            title="There were errors saving."
            centered
            header-bg-variant="dark"
            header-text-variant="light"
            hide-footer
            hide-header-close
        >
            <div class="my-2">
                <h3>{{ episodeErrors.title }}</h3>
                <ul>
                    <li v-for="error in episodeErrors.content" :key="error">{{ error }}</li>
                </ul>
            </div>
            <div class="text-center">
                <app-button @click="closeAnyModal('modal-error-saving')">EDIT</app-button>
                <app-button @click="exitEpisode()" isDanger>DISCARD</app-button>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
import axios from "axios";
import {pageHandler} from "../../mixins/constants.js";
import {mapGetters, mapActions, mapMutations} from "vuex";
import {careDirective, episode, clinicalInfo} from "@/classes/classes.js";
import EpisodeResidentData from "./EpisodeResidentData.vue";
import EpisodeClinicalInformation from "@/components/Episode/EpisodeClinicalInformation";
import AppButton from "@/components/AppButton";
import EpisodeMedicationReconcilliation from "@/components/Episode/EpisodeMedicationReconcilliation";
import EpisodeCareTeam from "@/components/Episode/EpisodeCareTeam";
import EpisodeVitalSigns from "@/components/Episode/EpisodeVitalSigns";
import EpisodeIntervention from "@/components/Episode/EpisodeIntervention";
import EpisodeSignsAndSymptoms from "@/components/Episode/EpisodeSignsAndSymptoms";
import ResidentCareDirectives from "@/components/resident/child/CareDirectives";
import ImmunizationInfo from "@/components/Immunization/Immunizations";

export default {
    props: {
        mode: {
            type: String,
            required: true,
        },
        refresh: {
            type: Function,
            required: true,
        },
        page: {
            type: String,
            required: false,
            default: "basicData",
        },
    },
    components: {
        EpisodeResidentData,
        EpisodeClinicalInformation,
        EpisodeMedicationReconcilliation,
        EpisodeCareTeam,
        EpisodeVitalSigns,
        EpisodeSignsAndSymptoms,
        EpisodeIntervention,
        AppButton,
        ResidentCareDirectives,
        ImmunizationInfo,
    },
    async mounted() {
        await this.loadData()
    },
    async created() {
        this.setDisplayPage()
    },
    destroyed() {
        this.unloadEpisode();
    },
    data() {
        return {
            displayEditorModal: false,
            saved: {
                mode: null,
                id: null,
                state: "unsaved",
                messages: "",
            },
            devices: [],
            continence: [],
            eating_intstrunction_selections: [],
            cardiac_types_selected: [],
            respiratory_items_selected: [],
            therapy_selections: [],
            risks_selected: [],
            infections_selected: [],
            edit_mode: "",
            ethnicities_other_id: 4,
            primary_care_providers: [],
            facility_staffFromAPI: [],
            showBasicInfoSubMenu: true,
            tubeFeedProductStatus: 0,
            showBasicData: true,
            showClinicalInfo: false,
            showCareDirectives: false,
            showMedicationRecon: false,
            showImmunization: false,
            showCareTeams: false,
            showSignsAndSymptoms: false,
            showVitalSigns: false,
            showInterventions: false,
            isLoading: true,
            isSaving: false,
            original_episode: new episode(this.facility_id), //  this is the base episode data, effectively the existing episode.
            episode: new episode(this.facility_id),
            saveButtonText: "SAVE",
            showMsgBtn: false,
        };
    },

    methods: {
        ...mapActions({
            fetchEpisode: "episode/fetchEpisode",
            unloadEpisode: "episode/unloadEpisode",
            saveEpisode: "episode/saveEpisode",
        }),
        ...mapMutations({
            setEpisodeId: "episode/setEpisodeId",
            updateCareDirective: "episode/updateCareDirective",
            setPage: "dashboard/setResidentPage",
        }),
        async saveResidentData() {
            this.isSaving = true
            const success = await this.saveEpisode();
            this.isSaving = false

            if (success === true) {
                this.saveButtonText = "Save";
                this.$bvModal.hide("residentModal");
            } else {
                console.error(
                    "Update Patient Errors: ",
                    JSON.stringify(this.episodeErrors)
                );

                this.saveButtonText = "Try Again";
                this.$refs["modal-error-saving"].show();
            }

        },
        exitEpisode() {
            this.$bvModal.hide("residentModal");
        },
        assignTheDropdownMultipleValuesCareDirective() {
            this.getEpisode.care_directives[0].devices = this.devices;
            this.getEpisode.care_directives[0].continence = this.continence;
            this.getEpisode.care_directives[0].nutrition.eating_instructions =
                this.eating_intstrunction_selections;
            this.getEpisode.care_directives[0].cardiac_treatments.cardiac.types.selected =
                this.cardiac_types_selected;
            this.getEpisode.care_directives[0].respiratory.selected =
                this.respiratory_items_selected;
            this.getEpisode.care_directives[0].therapies.selected =
                this.therapy_selections;
            this.getEpisode.care_directives[0].risks.selected = this.risks_selected;
            this.getEpisode.care_directives[0].infection_control.selected =
                this.infections_selected;
        },
        assignDataCareDirective() {
            // assignDataCareDirective
        },
        validationCheck() {

            if (this.isLoading === false) {
                return false;
            }

            if (!this.hasValidMrnNumber) {
                this.saved.messages =
                    "The MRN Number must be Numeric and have 6 characters.";
                return false;
            }

            if (!this.hasValidSocialSecurityNumber) {
                this.saved.messages =
                    "The Social Security Number must be in the format XXX-XX-XXXX.";
                return false;
            }

            if (!this.hasValidResidentFirstName) {
                this.saved.messages =
                    "You must enter a Resident First Name of at least 1 character.";
                return false;
            }

            if (!this.hasValidResidentLastName) {
                this.saved.messages =
                    "You must enter a Resident Last Name of at least 1 character.";
                return false;
            }

            if (!this.hasValidResidentDateOfBirth) {
                this.saved.messages = "You must select a Date of Birth.";
                return false;
            }

            if (!this.hasValidBloodGroup) {
                this.saved.messages = "You must select a Blood Group.";
                return false;
            }

            if (!this.hasValidGender) {
                this.saved.messages = "You must select a Gender.";
                return false;
            }

            if (!this.hasValidAccountNumber) {
                this.saved.messages =
                    "You must enter an account number of at least 3 characters.";
                return false;
            }

            if (!this.hasValidResidentDateOfAdmission) {
                this.saved.messages = "You must select a Date of Admission.";
                return false;
            }

            if (!this.hasValidLanguage) {
                this.saved.messages = "You must select a Language.";
                return false;
            }

            if (!this.hasValidLanguageOther) {
                this.saved.messages =
                    "You have selected Language Other. You must enter the Other Language.";
                return false;
            }

            if (!this.hasValidEthnicity) {
                this.saved.messages = "You must select an Ethnicity.";
                return false;
            }

            if (!this.hasValidEthnicityOther) {
                this.saved.messages =
                    "You have selected Ethnicity Other. You must enter the Other Ethnicity.";
                return false;
            }

            if (!this.hasValidMedicalInsurancePlan) {
                this.saved.messages = "You must select a Medical Insurance Plan.";
                return false;
            }

            if (!this.hasValidCareType) {
                this.saved.messages = "You must select a Care Type.";
                return false;
            }

            return true;

            //  TODO: Add care giver / guardian validation, minimum count 1
            //  TODO: Add Primare Care Provider count validation, minimum count 1
        },
        showBasicInfoValidationModal() {
            this.$refs["modal-for-basic-info-validation"].show();
        },
        showConfirmUnsavedChangesModal() {
            this.$refs["modal-unsaved-changes"].show();
        },
        assignPCPToEpisodeValue() {
            var loList = [];

            if (this.facility_staffFromAPI.length > 0) {
                this.primary_care_providers.forEach((element) => {
                    loList.push(
                        this.facility_staffFromAPI.find(
                            (u) => u.id === element && u.id != null
                        )
                    );
                });
            }

            this.getEpisode.primary_care_providers = loList;
        },
        assignEpisodeToPCPMultiSelect() {
            var loList = [];

            if (this.facility_staffFromAPI.length > 0) {
                this.getEpisode.primary_care_providers.forEach((element) => {
                    let loFindItem = this.facility_staffFromAPI.find(
                        (u) => u.id === element.id && u.id != null
                    );

                    if (loFindItem != undefined) {
                        loList.push(loFindItem.id);
                    }
                });
            }

            this.primary_care_providers = loList;
        },
        closeModal() {
            if (this.isDirty) {
                this.showConfirmUnsavedChangesModal();
                return;
            }

            this.unloadEpisode()
            this.$bvModal.hide("residentModal");
        },
        closeAnyModal(modal) {
            this.$bvModal.hide(modal)
        },
        async getEpisodeInformationById() {
            this.isLoading = true;
            // this.getEpisode();
            this.careDirectiveInit();
            this.assignDataCareDirective();
            this.getClinicalInformationForEpisode();
            this.isLoading = false;
        },
        async getEpisodeInformationAlt() {
            this.isLoading = true;
            this.getEpisode;
            this.careDirectiveInit();
            this.assignDataCareDirective();
            this.getClinicalInformationForEpisode();
            this.isLoading = false;
        },
        async getEpisodeInformation() {
            if (this.episodeId == 0) {
                this.showMsgBtn = false;
            } else {
                this.isLoading = true;
                this.getEpisode;
                this.careDirectiveInit();
                this.assignDataCareDirective();
                this.getClinicalInformationForEpisode();
                this.isLoading = false;
                this.showMsgBtn = true;
            }
        },
        async loadData() {

            this.isLoading = true

            if (this.mode === "edit") {

                // await this.fetchEpisode()
                await this.getEpisodeInformationById()
                this.setDisplayPage()
            }

            this.isLoading = false
        },
        setDisplayPage() {
            this.setAllPagestoHidden();
            switch (pageHandler.page) {
                case "basicData":
                    this.showBasicData = true;
                    break;
                case "clinicalInfo":
                    this.showClinicalInfo = true;
                    break;
                case "careDirectives":
                    this.showCareDirectives = true;
                    break;
                case "medicationRecon":
                    this.showMedicationRecon = true;
                    break;
                case "immunizationInfo":
                    this.showImmunization = true;
                    break;
                case "careTeams":
                    this.showCareTeams = true;
                    break;
                case "signsAndSymptoms":
                    this.showSignsAndSymptoms = true;
                    break;
                case "vitalSigns":
                    this.showVitalSigns = true;
                    break;
                case "interventions":
                    this.showInterventions = true;
                    break;
                default:
                    this.showBasicData = true;
            }
        },
        setAllPagestoHidden() {
            this.showBasicData = false;
            this.showClinicalInfo = false;
            this.showCareDirectives = false;
            this.showMedicationRecon = false;
            this.showImmunization = false;
            this.showCareTeams = false;
            this.showSignsAndSymptoms = false;
            this.showVitalSigns = false;
            this.showInterventions = false;
        },
        doShowBasicInfo() {
            pageHandler.page = "basicData";
            this.setDisplayPage();
        },
        doShowResidentReg() {
            pageHandler.page = "basicData";
            this.setDisplayPage();
        },
        doShowClinicalInfo() {
            pageHandler.page = "clinicalInfo";
            this.setDisplayPage();
        },
        doShowCareDirective() {
            pageHandler.page = "careDirectives";
            this.setDisplayPage();
        },
        doShowMedicationRecon() {
            pageHandler.page = "medicationRecon";
            this.setDisplayPage();
        },
        doShowImmunization() {
            pageHandler.page = "immunizationInfo";
            this.setDisplayPage();
        },
        doShowCareTeams() {
            pageHandler.page = "careTeams";
            this.setDisplayPage();
        },
        doShowSignsAndSymptoms() {
            pageHandler.page = "signsAndSymptoms";
            this.setDisplayPage();
        },
        doShowInterventions() {
            pageHandler.page = "interventions";
            this.setDisplayPage();
        },
        doShowVitalSigns() {
            pageHandler.page = "vitalSigns";
            this.setDisplayPage();
        },
        didSignAndSymptomSave(value) {
            if (value === true) {
                this.saveButtonText = "SAVED";
                this.$store.commit("setDoSignsAndSymptomsSave", false);
                setTimeout(() => (this.saveButtonText = "SAVE"), 3000);
            }
        },
        openMsgWindow(episode) {
            this.$root.selected_resident = episode;
            this.$root.is_msg_window_open = true;
            this.$store.commit("setIsChatWindowOpen", true);
            if (this.$root.selected_resident.intervention_ids.length > 0) {
                for (var i in this.$root.selected_resident.intervention_ids) {
                    if (
                        this.$root.selected_resident.intervention_ids[i].status ===
                        "Priority"
                    ) {
                        this.$root.selected_resident.priority_high = true;
                    }
                }
            }
            this.$bvModal.show("chatModal");
            setTimeout(function () {
                document.getElementById("msgSidePopUp").style.width = "30%";
            }, 200);
        },
        getClinicalInformationForEpisode() {
            this.high_risk = this.getEpisode.high_risk_conditions;
            this.findings = this.getEpisode.procedures ?? null;

            if (this.findings !== null) {
                this.findings.procedure_list = this.findings["procedure_list"]
                    ? JSON.parse(this.findings["procedure_list"])
                    : [];
            }

            this.meds_allergies = this.getEpisode.medications;
        },

        createClinicalInfoForEpisode() {
            //High Risk Conditions
            if (this.high_risk) {
                this.high_risk.episode_id = this.getEpisode.id;
                this.high_risk.patient_id = this.getEpisode.patient.id;
                axios
                    .post("/clincal-info/conditions/", this.high_risk)
                    .catch((error) => {
                        console.error(error);
                        if (error.response) {
                            // Request made and server responded
                            console.error(error.response.data);
                            console.error(error.response.status);
                            console.error(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.error(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.error("Error", error.message);
                        }
                    });
            }

            if (this.findings) {
                //Procedures and Key Findings
                this.findings.episode_id = this.getEpisode.id;
                this.findings.patient_id = this.getEpisode.patient.id;
                axios
                    .post("/clincal-info/procedures/", this.findings)
                    .catch((error) => {
                        console.error(error);
                        if (error.response) {
                            // Request made and server responded
                            console.error(error.response.data);
                            console.error(error.response.status);
                            console.error(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.error(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.error("Error", error.message);
                        }
                    });
            }

            if (this.meds_allergies) {
                //Medications and Allergies
                this.meds_allergies.episode_id = this.getEpisode.id;
                this.meds_allergies.patient_id = this.getEpisode.patient.id;
                axios
                    .post("/clincal-info/medications/", this.meds_allergies)
                    .catch((error) => {
                        console.error(error);
                        if (error.response) {
                            // Request made and server responded
                            console.error(error.response.data);
                            console.error(error.response.status);
                            console.error(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.error(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.error("Error", error.message);
                        }
                    });
            }
        },
        updateClinicalInfoForEpisode() {
            //High Risk Conditions
            if (this.high_risk) {
                this.high_risk.episode_id = this.getEpisode.id;
                this.high_risk.patient_id = this.getEpisode.patient.id;
                axios
                    .put("/clincal-info/conditions/", this.high_risk)
                    .catch((error) => {
                        console.error(error);
                        if (error.response) {
                            // Request made and server responded
                            console.error(error.response.data);
                            console.error(error.response.status);
                            console.error(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.error(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.error("Error", error.message);
                        }
                    });
            }

            if (this.findings) {
                //Procedures and Key Findings
                this.findings.episode_id = this.getEpisode.id;
                this.findings.patient_id = this.getEpisode.patient.id;
                axios.put("/clincal-info/procedures/", this.findings).catch((error) => {
                    console.error(error);
                    if (error.response) {
                        // Request made and server responded
                        console.error(error.response.data);
                        console.error(error.response.status);
                        console.error(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.error(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.error("Error", error.message);
                    }
                });
            }

            if (this.meds_allergies) {
                //Medications and Allergies
                this.meds_allergies.episode_id = this.getEpisode.id;
                this.meds_allergies.patient_id = this.getEpisode.patient.id;
                this.meds_allergies.pain_meds_status = parseInt(
                    this.meds_allergies.pain_meds_status
                );
                axios
                    .put("/clincal-info/medications/", this.meds_allergies)
                    .catch((error) => {
                        console.error(error);
                        if (error.response) {
                            // Request made and server responded
                            console.error(error.response.data);
                            console.error(error.response.status);
                            console.error(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.error(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.error("Error", error.message);
                        }
                    });
            }
        },

        careDirectiveInit() {
            if (this.getEpisode.care_directives == null) {
                this.getEpisode.care_directives = new careDirective();
            }
            if (this.getEpisode.clinical == null) {
                this.getEpisode.clinical = new clinicalInfo();
            }
        },
    },
    computed: {
        pageHandler() {
            return pageHandler
        },
        ...mapGetters({
            getRole: "user/getRole",
            getEpisode: "episode/getEpisode",
            getEpisodeId: "episode/getEpisodeId",
            isDirty: "episode/isDirty",
            getOriginalEpisode: "episode/getOriginalEpisode",
            mrnNumberIsValid: "episode/mrnNumberIsValid",
            socialSecurityNumberIsValid: "episode/socialSecurityNumberIsValid",
            saveErrors: "episode/errors",
            episodeErrors: "episode/episodeErrors",
            getResidentPage: "dashboard/getResidentPage",
        }),

        hasValidResidentFirstName: function () {
            return (
                this.getEpisode.patient?.name !== "" &&
                this.getEpisode.patient?.name !== null &&
                this.getEpisode.patient?.name.length >= 1
            );
        },
        hasValidResidentLastName: function () {
            return (
                this.getEpisode.patient.last_name !== "" &&
                this.getEpisode.patient.last_name !== null &&
                this.getEpisode.patient.last_name.length >= 1
            );
        },
        hasValidResidentDateOfBirth: function () {
            return (
                this.getEpisode.patient.date_of_birth !== "" &&
                this.getEpisode.patient.date_of_birth !== null
            );
        },
        hasValidBloodGroup: function () {
            return (
                this.getEpisode.patient.blood_group !== "" &&
                this.getEpisode.patient.blood_group !== null &&
                this.getEpisode.patient.blood_group !== 0
            );
        },
        hasValidGender: function () {
            return (
                this.getEpisode.patient.gender_id !== "" &&
                this.getEpisode.patient.gender_id !== null &&
                this.getEpisode.patient.gender_id !== 0
            );
        },
        hasValidAccountNumber: function () {
            return (
                this.getEpisode.account_number !== "" &&
                this.getEpisode.account_number !== null &&
                this.getEpisode.account_number.length >= 3
            );
        },
        hasValidResidentDateOfAdmission: function () {
            return (
                this.getEpisode.admission_date !== "" &&
                this.getEpisode.admission_date !== null
            );
        },
        hasValidLanguage: function () {
            return (
                this.getEpisode.patient.language_id !== "" &&
                this.getEpisode.patient.language_id !== null &&
                this.getEpisode.patient.language_id !== 0
            );
        },
        hasValidLanguageOther: function () {
            if (this.getEpisode.patient.language_id === 3) {
                return (
                    this.getEpisode.patient.language_other !== "" &&
                    this.getEpisode.patient.language_other !== null &&
                    this.getEpisode.patient.language_other.length >= 3
                );
            } else {
                return true;
            }
        },
        hasValidEthnicity: function () {
            return (
                this.getEpisode.patient.ethnicity_id !== "" &&
                this.getEpisode.patient.ethnicity_id !== null &&
                this.getEpisode.patient.ethnicity_id !== 0
            );
        },
        hasValidEthnicityOther: function () {
            return this.getEpisode.patient.ethnicity_id === 4
                ? this.getEpisode.patient.ethnicity_other !== "" &&
                this.getEpisode.patient.ethnicity_other != null &&
                this.getEpisode.patient.ethnicity_other.length >= 3
                : true;
        },
        hasValidMedicalInsurancePlan: function () {
            return (
                this.getEpisode.medical_insurance_id !== "" &&
                this.getEpisode.medical_insurance_id !== null &&
                this.getEpisode.medical_insurance_id !== 0
            );
        },
        hasValidCareType: function () {
            return (
                this.getEpisode.care_type_id !== "" &&
                this.getEpisode.care_type_id !== null &&
                this.getEpisode.care_type_id !== 0
            );
        },
        patientName: function () {

            if (this.getEpisode && this.getEpisode.patient && this.getEpisode.patient.name && this.getEpisode.patient.last_name) {

                return this.getEpisode.patient.name + ' ' + this.getEpisode.patient.last_name
            }

            return "New Patient"
        },
        roomNumber: function () {
            if (this.getEpisode.bed_number !== "") {
                return this.getEpisode.bed_number;
            } else {
                return "New Room Number";
            }
        },
        hasEdits: function () {
            const originalEpisode = JSON.stringify(this.original_episode);
            const currentEpisode = JSON.stringify(this.getEpisode);

            return originalEpisode.localeCompare(currentEpisode) !== 0;
        },
        showSaveButton: function () {
            return this.isDirty;
        },
    },
};
</script>

<style scoped>
.resident-editor--main {
    flex: 0 0 auto;
}
</style>